<template>
<div class="page">
  
   <headerPreview v-if="is_preview"></headerPreview>
   <Headers v-else ></Headers>
   <div class="con">
      <div class="listarea">
        <div class="listheader">
          <div class="titlebox">
            <img src="../../../assets/collect/titleicon1.png" alt="" class="icon" >
            <div class="listtitle">{{city.title}}</div>
          </div>
        </div>
        <div class="list">
            <div v-for="item in city.list" :key="item.id" class="listitem" @click="todetail(item.city_id)">
              <img  :src="item.cover_url" alt="" class="listitemimg">
              <div class="listitemtitle">{{item.name}}</div>
              <div class="listitemline">{{item.intro}}</div>
            </div>

        </div>
        
      </div>
      <div class="listarea">
        <div class="listheader">
          <div class="titlebox">
            <img src="../../../assets/collect/titleicon1.png" alt="" class="icon" >
            <div class="listtitle">{{district.title}}</div>
          </div>
        </div>
        <div class="list">
            <div v-for="item in district.list" :key="item.id" @click="todetail(item.city_id)"  class="listitem">
              <img :src="item.cover_url" alt="" class="listitemimg">
              <div class="listitemtitle">{{item.name}}</div>
              <div class="listitemline">{{item.intro}}</div>
            </div>
        </div>
        
      </div>

   </div>
    
</div>
</template>
<script>
import {allList} from '@/api/website/collect'
import Headers from '@/components/collect/header'
import headerPreview from '@/components/collect/headerPreview'
export default {
  components:{Headers,headerPreview},
  props:{
    is_preview:0
  },
  mounted() {
    this.getList()
  },

  data() {
    return{
      city: {
        title:'省会/地级市',
        list:[
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          }, {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
           {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
        ]
      },
      district:{
        title:'区/县/市',
        list:[
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },{
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          }
        ]
      }
    }
  },

  methods:{

    todetail(id){
      if(this.is_preview){
         this.$router.push({path:'/preview/detail/'+id,})
      }else{
         this.$router.push({path:'/collect/detail/'+id,})
      }
     
    },

    getList(){
      const params = {
        is_view:this.is_preview
      }
      allList(params).then(res=>{
        this.city = res.data.city
        this.district = res.data.district
      })
    }
    
  },
  data() {
    return{
      city: {
        title:'省会/地级市',
        list:[
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          }, {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
           {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
        ]
      },
      district:{
        title:'区/县/市',
        list:[
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },{
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          },
          {
            name:'南京市',
            intro:'创新名城 美丽古都'
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>


.page{
  width: 100%;
  height: 100%;
  background: linear-gradient( 180deg, #DFF4F5 0%, #F7FCFC 76%, #FEFEFE 100%);

}


.con{
  width: 100%;
  height: 100%;

  padding-bottom:100px;
  background: url('../../../assets/collect/bottombg1.png') no-repeat left 0 bottom 0;
}
.listarea{
  
  width:1200px;
  height: 100%;
  margin:0 auto;
  margin-top:38px;
  .listheader{
    width: 100%;
    height: 80px;
    background: url(../../../assets/collect/listtitle.jpg) no-repeat;
    .titlebox{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
 
      .icon{
        width: 52px;
        height: 52px;
        
      }
      .listtitle{
        height: 50px;
        font-weight: 500;
        font-family: FZQingKeBenYueSongS-R-GB, FZQingKeBenYueSongS-R-GB;
        font-weight: 400;
        font-size: 40px;
        line-height: 52px;
        text-shadow: 0px 4px 4px rgba(0,0,0,0.25);
        color:#fff;
        margin-left:15px;
    //  background: red;
 
      }
    }
  }
  .list{
  width: 1200px;
  background: rgba(255,255,255,0.8);
  box-shadow: 0px 4px 12px 0px rgba(179,211,198,0.5);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; /* 默认值，不移除换行 */
  .listitem{
    margin-top:15px;
    width: 395px;
    padding-left:20px;
    box-sizing: border-box;
    padding-right:5px;
    // background:red;
    display: flex;
    flex-direction: column;
    align-items:flex-start ;
    justify-content:flex-start;
    cursor: pointer;

  .listitemimg{
    width: 370px;
    height: 212px;
    border-radius: 0px 0px 0px 0px;
  }
  .listitemtitle{
 
    height: 26px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    line-height: 26px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .listitemline{
    width:100%;
      height: 22px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 14px;
      color: #565656;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
   
  }
  .listitembtn{
    width: 100px;
    height: 30px;
    background: #009688;
  }
}
}

  
}
.quxianshi{
  margin-top:59px;
}



</style>