import request from '@/utils/request'
import loginRequest from '@/utils/loginRequest'
import collectRequest from '@/utils/collectRequest'
import Qs from 'qs'


//配置list
export function allList(params) {
	return loginRequest({
		url: '/frontend/cityshow/home/city-list',
		method: 'post',
		data: Qs.stringify(params)
	})
}

//获取周边的地块的地图接口
export function aroundLand(params) {
	return loginRequest({
		url: '/frontend/cityshow/home/map-list',
		method: 'post',
		data: Qs.stringify(params)
	})
}








export function cityInfo(params) {
    return loginRequest({
		url: '/frontend/cityshow/home/city-info',
		method: 'post',
		data: Qs.stringify(params)
	})
}



export function tradedata(params) {
    return loginRequest({
		url: '/frontend/cityshow/home/cj-data',
		method: 'post',
		data: Qs.stringify(params)
	})
}


export function tabledata(params) {
    return loginRequest({
		url: '/frontend/cityshow/home/table',
		method: 'post',
		data: Qs.stringify(params)
	})
}




//获取周边配套和宗地风险点的地图接口
export function zhoubianData(params) {
	return loginRequest({
		url: '/frontend/cityshow/home/map-spot',
		method: 'post',
		params
	})
}


export function landDetail(params) {
	return loginRequest({
		url: '/frontend/cityshow/home/detail',
		method: 'post',
		params
	})
}


export function LoginedlandDetail(params) {
	return loginRequest({
		url: '/frontend/cityshow/home/detail',
		method: 'post',
		params
	})
}




export function getDetail(params) {
	return loginRequest({
		url: '/frontend/cityshow/home/map-detail',
		method: 'post',
		params
	})
}

export function surveyOptions(params) {
	return collectRequest({
		url: '/frontend/cityshow/home/survey-options',
		method: 'post',
		params
	})
}




export function saveSuggest(params) {
	return collectRequest({
		url: '/frontend/cityshow/home/survey-submit',
		method: 'post',
		params
	})
}



export function headerad(params) {
	return loginRequest({
		url: '/frontend/cityshow/home/header',
		method: 'post',
		params
	})
}












// http://dataapi.chinatupai.com:8080/api/frontend/cityshow/home/cj-data


// https://dataapi.chinatupai.com:447/api/frontend/bigdata/landdata/map-detail?id=51096&is_gaode=2

//申请资料
// export function loginedApply(params) {
// 	return request({
// 		url: '/frontend/website/bingo/data-apply',
// 		method: 'post',
// 		data: Qs.stringify(params)
// 	})
// }

