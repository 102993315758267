<template>

  <div class="headers">
    
        <img class="headerimg" :src="header_cover" alt="">
        <div style="width: 100%; MARGIN: 0 auto;position: fixed;top:20px;">
                <el-alert
                    title="非正式上线页面,仅供内部预览参考"
                    type="warning"
                    show-icon
                    center
                    :closable="false"></el-alert>
        </div>
        
  
   </div>
</template>

<script>
import {  headerad } from '@/api/website/collect.js'
export default {
    name:'collectHeader',
    data(){
        return{
            header_cover:''
        }
    },
    mounted(){
        headerad().then(res=>{
            this.header_cover = res.data.cover_url
        })
    },
}
</script>

<style lang="scss" scoped >
.headers{
  width: 100%;
  height: 360px;
  overflow: hidden; /* 隐藏超出部分 */
  position: relative; /* 相对定位 */
  
  .headerimg{
      // width: 100%; /* 图片宽度100% */
      height: 360px; 
      position: absolute; /* 绝对定位 */
      top: 50%; /* 垂直居中 */
      left: 50%; /* 水平居中 */
      transform: translate(-50%, -50%); /* 调整位置使其居中 */
      object-fit: cover; /* 图片覆盖容器，可能会裁剪 */
  }
}
</style>

